import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultProductType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_default_product_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListProductType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_list_product_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProductType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_product_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProductType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/add_product_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProductType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/edit_product_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProductType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/delete_product_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProductTypeOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/product_type_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
